import '@/styles/index.styl';
import agentTracker from '@/utils/webTrackBrowser';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import VueLuckyCanvas from '@lucky-canvas/vue';
import { createHead, VueHeadMixin } from '@unhead/vue';
import Sa from '@xuezhu/track';
import ElementUI from 'element-plus';
import 'element-plus/theme-chalk/index.css';
import * as Vue from 'vue';
import VuePdf from 'vue3-pdfjs';
import App from './App.vue';
import router from './router';
import store from './store';
import './styles/tailwindcss.css';
import zhCn from '@/utils/zhCn';

window.$vueApp = Vue.createApp(App);
// 重写console
function rewirteLog() {
  if (!window.console) {
    window.console = {};
  } else {
    console.log = (function (log) {
      return process.env.NODE_ENV == 'production' || process.env.NODE_ENV == 'prod' || process.env.NODE_ENV == 'pre' ? function () {} : log;
    })(console.log);
  }
}
rewirteLog();
const sa = Sa.create('Student_Internal', {
  env: process.env.NODE_ENV == 'production' || process.env.NODE_ENV == 'prod' ? 'prod' : 'test',
  system_type: 'web_pc',
  show_log: true,
});

window.$vueApp.$sa = sa;
// element-ui使用小号
window.$vueApp.use(ElementUI, {
  size: 'small',
  locale: zhCn,
});

window.$vueApp.$OSSStudentFilePrefix = `https://${process.env.NODE_ENV == 'production' || process.env.NODE_ENV == 'prod' ? '' : 'test-'}oss.stem86.com/images/student-img`;
window.$vueApp.$agentTracker = agentTracker;

// window.$vueApp.use(MetaInfo)
window.$vueApp.use(VueLuckyCanvas);
window.$vueApp.use(VuePdf);
window.$vueApp.use(createHead());
window.$vueApp.mixin(VueHeadMixin);
window.$vueApp.config.globalProperties.routerAppend = (path, pathToAppend) => {
  return path + (path.endsWith('/') ? '' : '/') + pathToAppend;
};
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  window.$vueApp.component(key, component);
}
window.$vueApp.use(store);
window.$vueApp.use(router);

window.$vueApp.mount('#app');
export default window.$vueApp;
