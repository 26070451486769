import browser from '@xuezhu/browser';

// 存储loalStorage
export const loalStorageSet = (key, value) => {
  if (!key) return;
  if (typeof value !== 'string') {
    value = JSON.stringify(value);
  }
  window.localStorage.setItem(key, value);
};
// 获取localStorage
export const loalStorageGet = (key) => {
  if (!key) return;
  return window.localStorage.getItem(key);
};
// 删除localStorage
export const loalStorageRemove = (key) => {
  if (!key) return;
  window.localStorage.removeItem(key);
};
// 清空localStorage
export const loalStorageClear = () => {
  window.localStorage.clear();
};
// 设置cookie
export const setCookie = (key, value, expire) => {
  const d = new Date();
  d.setDate(d.getDate() + expire);
  document.cookie = `${key}=${escape(value)};expires=${d.toUTCString()};path=/;domain=.stem86.com`;
};
// 读取cookie
export const getCookie = (key) => {
  const cookieStr = unescape(document.cookie);
  const arr = cookieStr.split('; ');
  let cookieValue = '';
  for (let i = 0; i < arr.length; i++) {
    const optTemp = arr[i].replace('=', '&&');
    const temp = optTemp.split('&&');
    if (temp[0] === key) {
      cookieValue = unescape(temp[1]);
      break;
    }
  }
  return cookieValue;
};
// 删除cookie
export const delCookie = (key) => {
  document.cookie = `${key}=;expires=${new Date().toUTCString()};path=/;domain=.stem86.com`;
};

// 校验身份证号码
export const checkCardNo = (value) => {
  const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
  return reg.test(value);
};
// 校验是否包含中文
export const haveCNChars = (value) => {
  return /[\u4e00-\u9fa5]/.test(value);
};
// 校验是否为邮箱地址
export const isEmail = (value) => {
  return /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value);
};
// 校验手机号
export const isTel = (value) => {
  return /^1[3,4,5,6,7,8,9][0-9]{9}$/.test(value.toString());
};
// 时间转换为string格式
export function currentTimeToString(time) {
  const year = time.getFullYear() < 10 ? `0${time.getFullYear()}` : `${time.getFullYear()}`;
  const month = time.getMonth() + 1 < 10 ? `0${time.getMonth() + 1}` : `${time.getMonth() + 1}`;
  const day = time.getDate() < 10 ? `0${time.getDate()}` : `${time.getDate()}`;
  const hours = time.getHours() < 10 ? `0${time.getHours()}` : `${time.getHours()}`;
  const minutes = time.getMinutes() < 10 ? `0${time.getMinutes()}` : `${time.getMinutes()}`;
  const seconds = time.getSeconds() < 10 ? `0${time.getSeconds()}` : `${time.getSeconds()}`;
  return [year, month, day, hours, minutes, seconds];
}

// 判断是否可以跳转OJ
export function validateChromeBrowser() {
  const browserInfo = browser();
  const { device, browser: browserName, version } = browserInfo;
  const bigVerson = version.split('.')[0];
  return ['Chrome', 'Edge'].includes(browserName) && bigVerson >= 90 && device === 'PC';
}

export function queryURLParams(url) {
  const pattern = /(\w+)=(\w+)/gi; // 定义正则表达式
  const parames = {}; // 定义参数对象
  url.replace(pattern, ($, $1, $2) => {
    parames[$1] = $2;
  });
  return parames;
}

export function winOpenTargetUrl(url, userInfo) {
  const { examUserId, categoryId } = userInfo;
  const str = `${examUserId}${categoryId}`;
  const CHILD_WINDOW_NAME = `stem86-考试-${str}`;
  const newurl = `${url}&diff=${str}`;
  let popup = window.open('', CHILD_WINDOW_NAME);
  const params = queryURLParams(newurl);
  if (params.diff == str && popup.location.href !== 'about:blank') {
    console.log('窗口已经存在了~');
    popup.focus();
    // popup.close();
    // popup = window.open(newurl, CHILD_WINDOW_NAME);
  } else {
    console.log('~窗口不存在~');
    popup = window.open(newurl, CHILD_WINDOW_NAME);
  }
  return popup;
}

// 跳转oj
export function timerInterPostMessageToSQ(url, userInfo, childName) {
  const CHILD_WINDOW_NAME = childName;
  const popup = window.open(url, CHILD_WINDOW_NAME);
  // console.log('打印传递给sq的消息', JSON.stringify(userInfo));
  // popup.postMessage(JSON.stringify(userInfo), url);
  window.timerInterPostMessageToSq = {
    timerInter: null,
    clearTimerInter: () => {
      clearInterval(window.timerInterPostMessageToSq.timerInter);
    },
  };
  let n = 1;
  window.timerInterPostMessageToSq.timerInter = setInterval(() => {
    if (n > 10) {
      window.timerInterPostMessageToSq.clearTimerInter();
    }
    console.log('打印传递给sq的消息', n.toFixed, JSON.stringify(userInfo));
    popup.postMessage(JSON.stringify(userInfo), url);
    n++;
  }, 500);
}

export const getOjUrl = `${window.location.protocol}//${
  // eslint-disable-next-line no-nested-ternary
  process.env.NODE_ENV === 'production' ? 'oj' : process.env.NODE_ENV === 'pre' ? 'gray-oj' : 'test-oj'
}.stem86.com/#/home`;

export function debounce(func, wait = 500, immediate = false) {
  let timeout;
  let context;
  let result;
  let callNow;

  function showResult(e1, e2) {
    result = func.apply(e1, e2);
    return result;
  }

  return function (...args) {
    context = this;
    if (timeout) clearTimeout(timeout);
    if (immediate) {
      callNow = !timeout;
      timeout = setTimeout(function () {
        timeout = null;
      }, wait);
      if (callNow) return showResult(context, args);
    } else {
      timeout = setTimeout(function () {
        clearTimeout(timeout);
        return showResult(context, args);
      }, wait);
    }
    return result;
  };
}

export function throttle(func, wait, options) {
  let timeout;
  let context;
  let args;
  // let result;
  let previous = 0;
  if (!options) options = {};

  const later = function () {
    previous = options.leading === false ? 0 : new Date().getTime();
    timeout = null;
    func.apply(context, args);
    if (!timeout) {
      args = null;
      context = args;
    }
  };

  const throttled = function () {
    const now = new Date().getTime();
    if (!previous && options.leading === false) previous = now;
    const remaining = wait - (now - previous);
    context = this;
    // eslint-disable-next-line prefer-rest-params
    args = arguments;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      func.apply(context, args);
      if (!timeout) {
        args = null;
        context = args;
      }
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
  };

  throttled.cancel = function () {
    clearTimeout(timeout);
    previous = 0;
    timeout = null;
  };

  return throttled;
}

// 等级考试等级转换为中文汉字
export function numberConversionToChineseCharacters(n) {
  const charactersArray = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二', '十三', '十四', '十五', '十六', '十七', '十八'];
  return charactersArray[n];
}

// 跳转oj开始考试上报浏览器日志
export function reportBrowserLog(examId, userInfo) {
  window.$vueApp.$agentTracker?.sendImmediate({
    env: process.env.NODE_ENV,
    keyWord: 'userAgent',
    user: {
      ...userInfo,
      ticket: 'stem86',
      token: loalStorageGet('token'),
      examId,
      accountId: 3,
    },
    info: browser(),
  });
}
